import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

declare global {
  interface Window {
    __env: any;
  }
}

if (window.__env) {
  for (let key in window.__env) {
    // @ts-ignore
    environment[key] = window.__env[key];
  }
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
